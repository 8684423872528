.hover-underline-animation {
  @apply inline-block relative text-current;
}

.hover-underline-animation::after {
  @apply absolute block w-full bg-current left-0;
  content: '';
  height: 1px;
  bottom: 1px;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation-alt {
  @apply inline-block relative text-current;
}

.hover-underline-animation-alt::after {
  @apply absolute block w-full bg-current left-0;
  content: '';
  height: 1px;
  bottom: 1px;
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-alt:hover::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  line-height: 1;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  svg {
    @apply left-1/2 top-1/2 absolute -translate-x-1/2 -translate-y-1/2 block;
    width: 150%;
    height: 150%;
    stroke: theme('colors.black');
    stroke-width: 0.2rem;
    stroke-linejoin: round;
    fill: none;

    path {
      transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease)
      var(--delay, 0s),
      stroke-dashoffset var(--duration, 0.85s) var(--easing, ease)
      var(--delay, 0s);
      stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
      stroke-dashoffset: var(--offset, 126px);
      transform: translateZ(0);
      &:nth-child(2) {
        --duration: 0.7s;
        --easing: ease-in;
        --offset: 100px;
        --array-2: 74px;
      }
      &:nth-child(3) {
        --offset: 133px;
        --array-2: 107px;
      }
    }
  }
  &.active {
    svg {
      path {
        --offset: 57px;
        &:nth-child(1),
        &:nth-child(3) {
          --delay: 0.15s;
          --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
        }
        &:nth-child(2) {
          --duration: 0.4s;
          --offset: 2px;
          --array-1: 1px;
        }
        &:nth-child(3) {
          --offset: 58px;
        }
      }
    }
  }
}