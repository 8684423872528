// Fonts
.r-text {
  &-xxxxl {
    @apply text-50 md:text-60 lg:text-150;
  }

  &-xxxl {
    @apply text-32 md:text-50 lg:text-70;
  }

  &-xxl {
    @apply text-32 md:text-40 lg:text-60;
  }

  &-xl {
    @apply text-28 md:text-40 lg:text-50;
  }

  &-lg {
    @apply text-28 md:text-32 lg:text-40;
  }

  &-md {
    @apply text-24 md:text-28 lg:text-32;
  }

  &-normal {
    @apply text-18 lg:text-24;
  }

  &-sm {
    @apply text-18 lg:text-20;
  }

  &-xs {
    @apply text-16 lg:text-18;
  }

  &-xxs {
    @apply text-14 lg:text-16;
  }
}

// Buttons
[class^="r-button"],
[class*="r-button"]{
  @apply font-graphik flex text-center justify-center items-center duration-200 transition-colors;
}
.r-button {
  &-white {
    @apply text-gray-700 bg-white border-2 border-white hover:bg-gray-700 hover:text-white hover:border-white;
  }

  &-black {
    &:not(.r-button-outline) {
      @apply text-white bg-gray-700 border-2 border-black hover:bg-white hover:text-gray-700 hover:border-gray-700;
    }
  }

  &-outline {
    &.r-button-black {
      @apply text-gray-700 bg-white border-2 border-gray-700 hover:bg-gray-700 hover:text-white;
    }
  }

  &-lg {
    @apply rounded-full font-semibold px-6 md:px-8 py-3 md:py-4 lg:py-7 text-18 lg:text-24;
  }

  &-md {
    @apply rounded-full font-semibold py-2 px-6 md:py-2 text-18 lg:text-20;
  }
}

// Links
[class^="r-link"],
[class*="r-link"]{
  @apply hover-underline-animation-alt;
}
.r-link {
  &-white {
    @apply text-white;
  }

  &-md {
    @apply font-normal text-18 lg:text-20;
  }
}

// Gaps
.r-gap {
  &-md {
    @apply gap-4 md:gap-5;
  }
}