.Wysiwyg {
  ul {
    list-style: initial;
    @apply pl-6;
  }

  a {
    @apply hover-underline-animation-alt;
  }

  table {
    @apply text-left r-text-sm leading-tight block md:table;
    width: 100% !important;

    tbody {
      @apply block md:table-row-group;
    }

    th,
    thead > * {
      @apply font-semibold border-b-2 border-black pb-4 px-0 md:px-3 align-top block md:table-cell;
    }

    tr {
      @apply block mt-8 md:mt-0 md:table-row;

      td {
        @apply border-b border-black py-4 px-0 md:px-3 align-top block md:table-cell;
      }

      &:last-child {
        th, td {
          @apply border-none;
        }
      }
    }
  }
}