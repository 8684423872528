.GdprConsentModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cc-background);
    z-index: 50;
    display: none;

    &.show {
        display: block;
    }

    .consent-wrapper {
        background-color: var(--cc-modal-box-background);
        border-radius: var(--cc-border-radius);
        color: var(--cc-text-color);
        padding: 1rem 1.5rem;
        position: fixed;
        z-index: 150;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: calc(100vw - 20px);
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 20px);
        overflow-x: hidden;
        overflow-y: auto;

        @media screen and (min-width: 480px) {
            max-width: 440px;
            padding: 2rem 3rem;
        }

        @media screen and (min-width: 768px) {
            top: 20%;
            max-width: 500px;
            transform: translate(-50%, 0);
            max-height: 80vh;
        }

        @media screen and (min-width: 1024px) {
            max-width: 620px;
        }

        @media screen and (min-width: 1280px) {
            max-width: 680px;
        }
    }
}

.GdprConsentModal,
.GdprConsentBrick {
    .script-list {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--cc-border-color);
        width: 100%;
        margin-top: 2rem;

        &-item {
            display: block;
            width: 100%;

            &.is-open {
                svg {
                    transform: rotate(180deg);
                }

                .script-item-info {
                    display: block;
                }
            }
        }
    }

    .script-item {
        &-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1rem 0;
            border-bottom: 1px solid var(--cc-border-color);

            &:focus span,
            &:active span {
                color: var(--cc-primary-color);
            }

            span {
                color: var(--cc-text-color-headline);
                font-weight: bold;

                font-size: 1rem;

                @media screen and (min-width: 480px) {
                    font-size: 1.25rem;
                }
            }
        }

        &-info {
            padding: 1rem;
            display: none;
            font-size: 0.75rem;
            background: var(--cc-border-color);
        }
    }

    .script-actions {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;

        &-btn {
            padding: 0.625rem 1.25rem;
            border-radius: var(--cc-border-radius);
            margin: 0.5rem 0;
            text-align: center;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;

            &:hover {
                background-color: var(--cc-btn-default-hover-bg);
            }

            &.is-primary {
                background-color: var(--cc-primary-color);
                color: white;

                &:hover {
                    background-color: var(--cc-btn-primary-hover-bg);
                }
            }

            &.is-secondary {
                background-color: var(--cc-secondary-color);
                color: white;

                &:hover {
                    background-color: var(--cc-btn-secondary-hover-bg);
                }
            }

            &.is-outlined {
                border-color: var(--cc-primary-color);
                color: var(--cc-primary-color);

                &:hover {
                    background-color: var(--cc-primary-color);
                    color: white;
                }
            }

            @media screen and (min-width: 768px) {
                margin: 0 0 0 1.5rem;
            }
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .GdprSwitch {
        position: relative;
        display: block;
        cursor: pointer;

        input {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;
            display: block;
            width: 4rem;
            height: 2rem;
            font-size: 1.2em;
            border-color: transparent;
            background-color: var(--cc-switch-bg-inactive);
            background-clip: padding-box;
            color: var(--cc-switch-bg-inactive);
            border-radius: 99px;
            vertical-align: middle;
            transition: all 0.25s linear 0.25s;

            @media screen and (min-width: 480px) {
                font-size: 2em;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }

            &::before {
                // @apply tw-shadow-md;
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                bottom: 2px;
                right: 50%;
                background-color: white;
                border-radius: 99px;
                background-clip: padding-box;
                z-index: 2;
                transform-origin: right center;
                animation: switch-off-transform 0.2s ease-out forwards;
            }

            &:checked {
                color: white;
                background-color: var(--cc-primary-color);
                border-color: transparent;

                &::before {
                    transform-origin: left center;
                    animation: switch-on-transform 0.2s ease-out forwards;
                }
            }

            &:focus {
                box-shadow: inset 0 0 0 2px var(--cc-text-color);
            }
        }
    }
}

.gdpr-element {
    .gdpr-wrap,
    .gdpr-text {
        display: none;
    }

    &.gdpr-loaded {
        .gdpr-wrap,
        .gdpr-text {
            display: block;
        }

        .gdpr-loading {
            display: none;
        }
    }
}

.GdprConsentBrick{
    display: flex;
    justify-content: center;
    padding: 10vw 50px;

    .GdprToggle{
        padding: 0.625rem 1.25rem;
        border-radius: var(--cc-border-radius);
        text-align: center;
        user-select: none;
        background-color: var(--cc-primary-color);
        border: 1px solid transparent;
        color: white;
        
        &:hover {
            background-color: var(--cc-btn-primary-hover-bg);
        }
    }
}

@keyframes switch-on-transform {
    0% { transform: translate(0) scaleX(1); }
    25% { transform: translate(0) scaleX(1.33); }
    100% { transform: translate(100%) scaleX(1); }
}

@keyframes switch-off-transform {
    0% { transform: translate(100%) scaleX(1); }
    25% { transform: translate(100%) scaleX(1.33); }
    100% { transform: translate(0) scaleX(1); }
}

body.modal-active {
    position: relative;
    height: 100vh;
    overflow: hidden;
}