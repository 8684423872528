@tailwind base;
@tailwind components;
@tailwind utilities;

@import "main/import-fonts";
@import "main/base";
@import "main/responsive";
@import "main/components";
@import "main/admin";
@import "main/wysiwyg";
@import "main/swiper";
@import "main/form";
@import "main/loader";
@import "main/gdpr-consent";
@import "main/footer";
@import "main/effects";
@import "main/fixes";
@import "main/editmode";