.ImageHotspots {
  .hotspot {
    &[aria-describedby] {
      svg {
        @apply scale-150;
      }
    }
  }

  // Tippy CSS
  .tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
  }

  [data-tippy-root] {
    max-width: calc(100vw - 10px)
  }

  .tippy-box {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    transition-property: transform, visibility, opacity;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .tippy-box[data-placement^=top] > .tippy-arrow {
    bottom: 0
  }

  .tippy-box[data-placement^=top] > .tippy-arrow:before {
    bottom: -4px;
    left: 0;
    border-width: 5px 5px 0;
    border-top-color: initial;
    transform-origin: center top
  }

  .tippy-box[data-placement^=bottom] > .tippy-arrow {
    top: 0
  }

  .tippy-box[data-placement^=bottom] > .tippy-arrow:before {
    top: -4px;
    left: 0;
    border-width: 0 5px 5px;
    border-bottom-color: initial;
    transform-origin: center bottom
  }

  .tippy-box[data-placement^=left] > .tippy-arrow {
    right: 0
  }

  .tippy-box[data-placement^=left] > .tippy-arrow:before {
    border-width: 5px 0 5px 5px;
    border-left-color: initial;
    right: -4px;
    transform-origin: center left
  }

  .tippy-box[data-placement^=right] > .tippy-arrow {
    left: 0
  }

  .tippy-box[data-placement^=right] > .tippy-arrow:before {
    left: -4px;
    border-width: 5px 5px 5px 0;
    border-right-color: initial;
    transform-origin: center right
  }

  .tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
  }

  .tippy-arrow {
    width: 16px;
    height: 16px;
    color: #333
  }

  .tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid
  }

  .tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1
  }

  .tippy-box[data-animation=scale][data-placement^=top] {
    transform-origin: bottom
  }

  .tippy-box[data-animation=scale][data-placement^=bottom] {
    transform-origin: top
  }

  .tippy-box[data-animation=scale][data-placement^=left] {
    transform-origin: right
  }

  .tippy-box[data-animation=scale][data-placement^=right] {
    transform-origin: left
  }

  .tippy-box[data-animation=scale][data-state=hidden] {
    transform: scale(.5);
    opacity: 0
  }
}