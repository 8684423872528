@font-face {
    font-family: "Lyon";
    src: url("/assets/fonts/LyonText-RegularItalic-Web.eot");
    src: url("/assets/fonts/LyonText-RegularItalic-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/LyonText-RegularItalic-Web.woff2") format("woff2"),
        url("/assets/fonts/LyonText-RegularItalic-Web.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: "Lyon";
    src: url("/assets/fonts/LyonText-Regular-Web.eot");
    src: url("/assets/fonts/LyonText-Regular-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/LyonText-Regular-Web.woff2") format("woff2"),
        url("/assets/fonts/LyonText-Regular-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("/assets/fonts/Graphik-SemiboldItalic-Web.eot");
    src: url("/assets/fonts/Graphik-SemiboldItalic-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
        url("/assets/fonts/Graphik-SemiboldItalic-Web.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("/assets/fonts/Graphik-Semibold-Web.eot");
    src: url("/assets/fonts/Graphik-Semibold-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/Graphik-Semibold-Web.woff2") format("woff2"),
        url("/assets/fonts/Graphik-Semibold-Web.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("/assets/fonts/Graphik-RegularItalic-Web.eot");
    src: url("/assets/fonts/Graphik-RegularItalic-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
        url("/assets/fonts/Graphik-RegularItalic-Web.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("/assets/fonts/Graphik-Regular-Web.eot");
    src: url("/assets/fonts/Graphik-Regular-Web.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/Graphik-Regular-Web.woff2") format("woff2"),
        url("/assets/fonts/Graphik-Regular-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
