@import "air-datepicker/air-datepicker.css";
@import "~dropzone/dist/dropzone.css";

.air-datepicker {
  --adp-day-name-color: theme("colors.black");
  --adp-cell-background-color-selected: theme("colors.black");
  --adp-cell-background-color-selected-hover: theme("colors.black");
  --adp-color-current-date: theme("colors.red");
}

.Form {
    .dropzone {
        @apply border-gray-300 mb-4 rounded-none focus:outline-black;
    }

    :not(.editmode) & {
        .person {
            @apply md:sticky top-20 h-fit;
        }
    }

    .dropzone {
        .dz-error-mark {
            @apply bg-black;
        }
    }

    label {
        &.required {
            &:after {
                content:"*";
            }
        }
    }
}
