body {
  -webkit-tap-highlight-color: transparent;

  &.mobile-nav-open {
    overflow-y: hidden;
  }
}

@layer utilities {
  .perspective-px {
    perspective: 1px;
  }
}