.tippy-box {
    @apply max-w-full;

    .tippy-content {
        @apply bg-white px-2.5 py-1.5;
    }

    .tippy-arrow {
        @apply w-2.5 h-2.5 text-white;

        &:before {
            content: "";
            position: absolute;
            border-color: transparent;
            border-style: solid;
        }
    }

    &[data-placement^="right"] {
        .tippy-arrow {
            left: 0;
            &:before {
                left: -7px;
                border-width: 8px 8px 8px 0;
                border-right-color: initial;
                transform-origin: center right;
            }
        }
    }

    &[data-placement^="left"] {
        .tippy-arrow {
            right: 0;

            &:before {
                border-width: 8px 0 8px 8px;
                border-left-color: initial;
                right: -7px;
                transform-origin: center left;
            }
        }
    }

    &[data-placement^="top"] {
        .tippy-arrow {
            bottom: 0;
            &:before {
                bottom: -7px;
                left: 0;
                border-width: 8px 8px 0;
                border-top-color: initial;
                transform-origin: center top;
            }
        }
    }

    &[data-placement^="bottom"] {
        .tippy-arrow {
            top: 0;
            &:before {
                top: -7px;
                left: 0;
                border-width: 0 8px 8px;
                border-bottom-color: initial;
                transform-origin: center bottom;
            }
        }
    }
}

.tippy-box[data-placement^="right"] .tippy-arrow {
    width: 10px;
    height: 0.625rem;
    color: white;
    left: 0;
}
