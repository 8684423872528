.TeaserSliderSmallComponent,
.BrandTeaserSliderComponent {
    .swiper-scrollbar.swiper-scrollbar-horizontal {
        .swiper-scrollbar-drag {
            @apply bg-gray-700 rounded-[1.25rem]
        }
    }

    .swiper-button-disabled {
        @apply opacity-0 invisible
    }
}