// Prevent layout shifts
body:not([data-editmode="true"]) {
  .swiper {
    &:not(.swiper-initialized) {
      .swiper-slide {
        &:not(:first-child) {
          @apply hidden;
        }
      }
    }
  }
}

.swiper {
  .swiper-pagination-bullet {
    @apply bg-gray-300 lg:bg-white opacity-100 h-3 w-3 md:h-4 md:w-4;
  }

  .swiper-pagination-bullet-active {
    @apply bg-gray-700 border-2 border-white;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply w-1/2 flex items-center justify-center;
  }
}
