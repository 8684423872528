:root {
  --cc-primary-color: theme('colors.black');
  --cc-secondary-color: theme('colors.black');
  --cc-text-color: theme('colors.gray.700');
  --cc-text-color-headline: theme('colors.black');
  --cc-modal-box-background: theme('colors.white'); // background of content
  --cc-border-radius: 1px;
  --cc-border-color: theme('colors.gray.300');
  --cc-switch-bg-inactive: theme('colors.gray.500');
  --cc-background: rgba(21, 34, 41, 0.85); // background for modal, body-before-element
  --cc-btn-primary-hover-bg: theme('colors.black');
  --cc-btn-secondary-hover-bg: theme('colors.black');
  --cc-btn-default-hover-bg: theme('colors.gray.500');
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";

.GdprConsentModal {
  z-index: 1000;

  .consent-wrapper{
    @media (max-width: 768px) {
      top: calc(50% + 50px) !important;
      padding-bottom: 20vh;
    }
  }

  .Headline {
    @apply font-semibold mb-4 md:mb-6;
  }

  .Editor {
    @apply md:flex md:flex-row md:flex-row-reverse;
    &::before {
      @apply block float-right -mt-4 ml-3 mb-3 md:ml-6 md:mb-6 md:float-none md:shrink-0 w-16 h-16 md:w-28 md:h-28;
      content: '';
      background: url('../assets/images/cookie.svg') no-repeat center center;
      background-size: contain;
    }
  }
}