.HomeSlider {
    .swiper-pagination-bullet {
        @apply bg-gray-500 md:bg-white opacity-100 h-3 w-3 md:h-4 md:w-4;
    }

    .swiper-pagination-bullet-active {
        @apply bg-gray-700 md:border-2 md:border-white;
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
        @apply w-1/2 flex items-center justify-center;
    }

    video {
        @apply object-center object-cover;
    }
}
